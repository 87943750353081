.navInnerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navLinkWrapper {
  display: flex;
  margin-right: 55px;
}

.navLinkWrapper a {
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  color: #a8a29e;
  font-size: 14px !important;
  box-shadow: none;
  margin: 0 5px;
}

.navLinkWrapper a svg {
  margin-right: 4px;
  margin-bottom: 2px;
}

@media screen and (max-width: 767px) {
  .navLinkWrapper a span {
    display: none;
  }

  .navLinkWrapper a img {
    display: block;
  }
}

@media screen and (max-width: 450px) {
  .navLinkWrapper svg {
    margin-right: 0px !important;
  }
  .navLinkWrapper {
    margin-right: 25px;
  }
}
