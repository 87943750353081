.col {
  font-variant-numeric: tabular-nums;
}
.row {
  text-align: right;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  background-color: #ffffff !important;
  padding: 12px 22px 12px 8px;
  align-items: center;
  line-height: 20px;
  font-weight: 400;
  vertical-align: middle;
  height: 40px;

  &:last-of-type {
    border-bottom: none !important;
  }

  & td {
    vertical-align: middle;
    padding-right: 26px;
    color: #44403c;
  }
}
.badge {
  font-size: 12px !important;
  font-weight: 400 !important;
}