.avatar {
  width: 30px;
  position: relative;
  padding-right: 0 !important;
  padding-left: 8px;
}
.member {
  position: absolute;
  top: 3px !important;
  right: 0;
  width: 16px !important;
  height: 16px !important;
}
.account {
  text-align: left;
  white-space: pre;
}
.address {
  background-color: #f5f5f4;
  font-weight: 500;
  padding: 5px 8px;
  line-height: 14px;
  margin-right: 8px;
  border-radius: 8px;
  font-size: 11.2px;
  cursor: pointer;
}
.ens {
  margin-right: 10px;
  font-weight: 400;
  margin-left: 10px;

  @media screen and (max-width: 767px) {
    text-overflow: clip;
    overflow: hidden;
    white-space: nowrap;
  }
}