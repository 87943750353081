.container {
  max-width: 1380px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 18px 80px 18px;

  @media screen and (max-width: 1200px) {
    width: auto;
  }
}

