.avatar img {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 18px;
  right: 15px;
  border: solid 0.5px #e4e4e7;
  border-radius: 100%;
  padding: 1px;
}

.unionStatCardHeader {
  position: relative;
}

.unionStatCardHeader p {
  font-weight: 500;
}

.unionStatCardText {
  font-weight: 500 !important;
  font-size: 14px !important;
}

.unionStatCardHeaderContent {
  padding: 16px 24px 16px 24px;
}

.unionStatCardHeaderContent label {
  font-weight: 400 !important;
}

.labelRight {
  text-align: right;
}

.labelLeft {
  text-align: left;
}