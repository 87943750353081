.row {
  padding: 14px 8px;
  border-radius: 8px;
  font-size: 12px;
  color: #78716c;
  background-color: rgba(250, 250, 249, 0.8) !important;
  width: 100%;
  top: -0.5px;
  z-index: 1;
  height: 42px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.item {
  padding: 11px 24px 13px 9px;
  white-space: pre;
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  color: #78716c;
  cursor: pointer;

  svg {
    margin-left: 0.3em;
    vertical-align: middle;

    path {
      fill: #CCC;
    }
  }

  &--desc {
    svg path:first-of-type {
      fill: #78716C;
    }
  }
  &--asc {
    svg path:last-of-type {
      fill: #78716C;
    }
  }
}
.item--account {
  padding: 14px 22px 13px 10px;
  text-align: left;
  min-width: 275px;
}
.item--status {
  text-align: left;
  padding-left: 4px;
}

@media screen and (max-width: 767px) {
  .item--account {
    padding-left: 10px;
  }
}