.filter-button {
  width: 100%;
  margin-top: 2em;
  color: white !important;
}
.control {
  border-bottom: 1px solid #F5F5F4;
  padding: 0.75em 0;
  flex-direction: column !important;

  &:first-of-type {
    padding-top: 0;
  }
}
.checkbox {
  margin-bottom: 0.5em !important;
}
.input {
  width: 100%;
  margin-top: 0.5em;
}