@import "@unioncredit/ui/lib";

body {
  background-color: #fff;
  overflow-y: scroll;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.token-wording {
  margin-left: 6px;
}

.label {
  position: relative;
}

.label-right {
  text-align: right;
  display: block;
}

input .input {
  height: 40px;
}

.purple-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  background-color: #3b82f6;
  top: 6px;
  left: 167px;
}

.indicator-purple {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  background-color: #a78bfa;
  left: 68px;
  top: 9px;
}

.blue-indicator-borrow,
.purple-indicator-borrow {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}

.purple-indicator-borrow {
  background-color: #a78bfa;
}

.blue-indicator-borrow {
  background-color: #60a5fa;
}

.yellow-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  background-color: #fbbf24;
  left: 183px;
  top: 50px;
}

.indicator-yellow {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  background-color: #fbbf24;
  left: 150px;
  top: 9px;
}

.indicator--blue {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  background-color: #3b82f6;
  top: 51px;
  right: 180px;
}

.red-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  background-color: #f87171;
  left: 107px;
  top: 9px;
}

.pink-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  background-color: #e879f9;
  left: 105px;
  top: 9px;
}

.select-option {
  max-height: 40px !important;
  min-height: 40px !important;
}

.select-ui div {
  z-index: 100 !important;
  font-size: 14px;
  font-weight: 400;
  border: none;
  background: rgb(250, 250, 249);
}

.VictoryContainer {
  overflow: hidden !important;
  pointer-events: unset !important;
  touch-action: unset !important;
}

.VictoryContainer tspan {
  font-size: 10px !important;
  color: #57534e !important;
}

.VictoryContainer svg line {
  stroke: #e7e5e4 !important;
}

/*
.victoryContainer {
  width: 300px;
  height: 300px;
}

 */

.active {
  box-shadow: 0 0 0 0.5px #e4e4e7 !important;
  color: #44403c !important;
}

.active svg path {
  fill: #44403c !important;
}

.text--x--large {
  font-size: 20px !important;
  font-weight: 500;
  margin-top: 4px;
}

.active-state {
  background-color: #ede9fe;
  color: #7c3aed;
}

.select-control {
  z-index: 100;
}
.select-control div:nth-of-type(3) {
  border-radius: 24px;
}

@media screen and (max-width: 1000px) {
  .Layout__main__wrapper {
    padding-bottom: 32px;
    box-sizing: border-box;
  }
}

.loading-screen {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}

.nav--mobile {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: 9999;
  background: #fbfbfb;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: inset 0 1px 0 #e1e0e6;
}

.copyable {
  cursor: pointer;
}

.editable-name {
  outline: none;
  min-height: 28px;
  min-width: 100px;
}

.button .icon-external {
  margin-left: 4px;
}

@media screen and (max-width: 650px) {
  .tooltip:not(.tooltip--alwaysShow) {
    display: none;
  }

  .Layout__header .heading {
    font-size: 21px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper-toggle-menu {
    align-self: flex-end;
  }
}

@media screen and (max-width: 440px) {
  .select-ui {
    max-width: 230px;
  }

  .text-left-mobile .label-right {
    text-align: left;
    margin-top: 8px;
  }

  .purple-indicator-borrow {
    left: 195px;
    top: 25px;
  }

  .blue-indicator-borrow {
    right: 0;
    left: 140px;
    top: 85px;
  }

  .yellow-indicator {
    top: 67px;
    left: 322px;
  }

  .victoryContainer {
    width: 300px;
    height: 300px;
  }

  .text--x--large {
    font-size: 18px !important;
  }

  .Layout__header {
    padding-top: 12px !important;
  }
}

ol,
ul {
  list-style: normal;
  padding-left: 20px;
  box-sizing: border-box;
}

.inverted-circle-logo {
  background-color: #44403c;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inverted-circle-logo svg path {
  fill: white;
}

.identicon {
  border-radius: 100%;
}

.union-wallet.button,
.wallet.button {
  height: 44px;
  min-height: 0;
}

.Layout__main {
  min-height: 100vh;
}

.Layout__header {
  display: block;
  padding-top: 16px;
  padding-bottom: 16px;
  background: none;
  border: none;
}

.divider {
  border-bottom: 1px solid #f4f4f6;
  width: 100%;
}

.divider-content {
  border-bottom: 0.5px solid #f5f5f4;
}

@media screen and (max-width: 767px) {
  .Layout__header {
    padding-bottom: 8px;
  }
}

.sortableLabel {
  cursor: pointer;
  user-select: none;
}

.sortableLabel td svg {
  position: relative;
  top: 1%;
}

.staked--desc path:nth-child(1) {
  fill: #78716c;
}

.staked--asc path:nth-child(2) {
  fill: #78716c;
}

.borrows--desc path:nth-child(1) {
  fill: #78716c;
}

.borrows--asc path:nth-child(2) {
  fill: #78716c;
}

.repays--desc path:nth-child(1) {
  fill: #78716c;
}

.repays--asc path:nth-child(2) {
  fill: #78716c;
}

.trust--desc path:nth-child(1) {
  fill: #78716c;
}

.trust--asc path:nth-child(2) {
  fill: #78716c;
}

.trust-count--desc path:nth-child(1) {
  fill: #78716c;
}

.trust-count--asc path:nth-child(2) {
  fill: #78716c;
}

.trust-count-given--asc path:nth-child(2) {
  fill: #78716c;
}

.trust-count-given--desc path:nth-child(1) {
  fill: #78716c;
}

.member-status--desc path:nth-child(1) {
  fill: #78716c;
}

.member-status--asc path:nth-child(2) {
  fill: #78716c;
}

.context-menu {
  width: auto;
  z-index: 999;
}
.context-menu-wrapper {
  z-index: 1;
}
.context-menu__item {
  color: #423d37;
  cursor: pointer;
  padding: 0.85em 1.25em 0.85em 1em;
  white-space: nowrap;
}
.pagination {
  padding: 0.5em 0;
  border-top: 0;
}
.network-select .select-ui {
  max-width: none;
}
